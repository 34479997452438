import React from "react";
import PropTypes from "prop-types";
import ProductDLAttrContainer from "../containers/ProductDLAttrContainer";
import { FormGroup, ControlLabel, FormControl, Button } from "react-bootstrap";
import { TOGGLE_DATALEVEL_EXPAND, TOGGLE, TOGGLE_EDIT_DATALEVEL } from "../actions";
import { formDataToJSON } from "../adukku_utils/array";
import { authorized, Authorized } from "../adukku_utils/authorizeUser";

class ProductDL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product_datalevel: props.product_datalevel,
      collapse: props.collapse,
      isEdit: props.isEdit,
      role: props.role,
      product_role: props.product_role,
    };
    this.onExpandToggle = this.onExpandToggle.bind(this);
    // this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCreateCancel = this.onCreateCancel.bind(this);
    this.newAttribute = this.newAttribute.bind(this);
  }

  onExpandToggle(e) {
    e.stopPropagation();
    e.preventDefault();
    this.props.toggleView({
      reducerKey: TOGGLE_DATALEVEL_EXPAND,
      id: this.state.product_datalevel.id,
    });
  }
  onEdit(e) {
    e.stopPropagation();
    e.preventDefault();
    this.props.toggleView({
      reducerKey: TOGGLE_EDIT_DATALEVEL,
      id: this.state.product_datalevel.id,
    });
  }
  onDelete(e) {
    e.stopPropagation();
    e.preventDefault();
    if (confirm("Are you sure ?")) {
      this.props.deleteProductDataLevel(this.state.product_datalevel.id);
    }
  }
  onCreateCancel(e) {
    e.stopPropagation();
    e.preventDefault();
    if (confirm("Are you sure ?")) {
      this.props.deleteProductDataLevel(this.state.product_datalevel.id, false);
    }
  }
  handleSubmit(e) {
    e.stopPropagation();
    e.preventDefault();
    let formData = formDataToJSON(new FormData(e.target));
    formData.dlname = formData.dlname.trim()
    let datalevel = this.state.product_datalevel;
    datalevel.dlname = formData.dlname;
    datalevel.dltype = formData.dltype;
    datalevel.id = datalevel.product_id + "/datalevels/" + datalevel.dlname;
    this.props.createProductDatalevel(datalevel.product_id, datalevel.dlname, datalevel.dltype);
    this.props.setExpand();
  }

  onInputClick(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  newAttribute(e) {
    e.stopPropagation();
    e.preventDefault();
    let dl = this.state.product_datalevel;
    let payload = {
      product_id: dl.product_id,
      dlname: dl.dlname,
      name: "ATTR" + dl.attributes.length,
      type: "text",
    };
    payload["id"] =
      payload.product_id + "/datalevels/" + payload.dlname + "/attributes/" + payload.name;
    this.props.newDatalevelAttribute(dl.id, payload);
  }

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  render() {
    const { product_role, role } = this.state;
    const datalevel = this.state.product_datalevel;
    const attributes = datalevel.attributes;
    const collapse = this.state.collapse;
    let attributesDOM = null;
    const isMain = datalevel.dltype == "main";
    let label = datalevel.dlname;
    if (isMain) {
      label = label + "-- main";
    }
    let readOnlyDOM = [
      <a className="no-textcolor" onClick={this.onExpandToggle}>
        {this.getExpandJSX(collapse)} {label}
      </a>,

      <Authorized
        userRoles={[role, product_role]}
        requiredRoles={["admin", "owner"]}
        roleType="ANY"
      >
        <a onClick={this.onDelete} style={{ paddingLeft: "15px" }} title="Delete">
          <img src="/icons/delete.png" style={{ width: "17px" }} />
        </a>
      </Authorized>,
    ];
    const isEdit = !this.props.isEdit ? false : this.state.isEdit;
    const editOnlyDOM = (
      <form className="form-inline override_form" onSubmit={this.handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            pattern="[a-zA-Z0-9_\-]*"
            title="Only Alphabets,numbers,-, and _ allowed"
            name="dlname"
            onClick={this.onInputClick}
            className="form-control dlname margin-right"
            placeholder="Enter Datalevel Name"
            required="required"
            defaultValue={null}
          />
        </div>
        <FormGroup controlId="formHorizontalDLName">
          <FormControl
            componentClass="select"
            placeholder="Please select"
            required="required"
            defaultValue={null}
            name="dltype"
            className="margin-right"
          >
            {["", "main", "other"].map((type, i) => {
              return (
                <option key={i} value={type}>
                  {type}
                </option>
              );
            })}
          </FormControl>
        </FormGroup>
        <button type="submit" className="btn btn-default margin-right">
          Save
        </button>
        <a onClick={this.onCreateCancel} className="btn btn-default btn-danger">
          Cancel
        </a>
      </form>
    );

    if (collapse == true) {
      attributesDOM = (
        <ul>
          {attributes.map((attr, i) => (
            <li key={attr.id}>
              <ProductDLAttrContainer
                key={attr.id}
                {...attr}
                role={role}
                product_role={product_role}
              />
            </li>
          ))}

          <Authorized
            userRoles={[role, product_role]}
            requiredRoles={["admin", "owner"]}
            roleType="ANY"
          >
            <li>
              <a onClick={this.newAttribute}>Create New Attribute</a>
            </li>
          </Authorized>
        </ul>
      );
    }
    if (datalevel == null) {
      return null;
    }
    return (
      <li key={datalevel.id}>
        {!isEdit && readOnlyDOM}
        {isEdit && editOnlyDOM}
        {attributesDOM}
      </li>
    );
  }
}

ProductDL.propTypes = {
  product: PropTypes.shape({
    dlname: PropTypes.string.isRequired,
    product_id: PropTypes.number.isRequired,
    collapse: PropTypes.bool.isRequired,
  }),
  attributes: PropTypes.array,
};

export default ProductDL;
