import "babel-polyfill";
import React from "react";
import CartState from "../context/cart/CartState";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import createLogger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import AdukkuApp from "../reducers/product_reducers";
import ProductListContainer from "../containers/ProductListContainer";
import ProductComposzNavbar from "./ProductComposzNavbar";

//const logger = createLogger({});
const sagaMiddleware = createSagaMiddleware();
//const store = createStore(AdukkuApp, applyMiddleware(logger, sagaMiddleware)); // Bala
const store = createStore(AdukkuApp, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

$(window).scroll(function () {
  //set scroll position in session storage
  sessionStorage.scrollPos = $(window).scrollTop();
});
var init = function () {
  //return scroll position in session storage
  $(window).scrollTop(sessionStorage.scrollPos || 0);
};
window.onload = init;

class MyProducts extends React.Component {
  componentDidMount() {
    let user_role = document.getElementById("user_role");
    user_role = user_role.innerHTML;
    if (localStorage.getItem("isOrderPaid")) {
      alert(
        "Payment done successfully, \n Download your files immediately or within the next 3 days."
      );
      localStorage.removeItem("isOrderPaid");
    }
    if (localStorage.getItem("isStoragePaid")) {
      alert("Payment done successfully, \n Check expiry date under Storage Purchase History");
      localStorage.removeItem("isStoragePaid");
    }
    // this.setState({ role: user_role });
    // alert(
    //   "Payment done successfully, \n Download your files immediately or within the next 3 days."
    // );
  }
  render() {
    return (
      <div id="topNav">
      <CartState>
       <ProductComposzNavbar user_id={this.props.user_id} user_name={this.props.user_name} user_email={this.props.user_email} has_view_products={this.props.has_view_products}></ProductComposzNavbar>
       <div style={{paddingLeft: 200}}>
       <Provider store={store}>
         <ProductListContainer />
       </Provider>
       </div>
      </CartState>
     </div>      
     );
   }
}
export default MyProducts;
