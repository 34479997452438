import React from "react";

class ThumbnailPreview extends React.Component {
  handleClose = () => {
    this.closeFullScreen();
    this.props.onClose();
  };
  closeFullScreen = () => {
    var isInFullScreen =
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null);

    if (document.exitFullscreen) {
      if (isInFullScreen) {
        document.exitFullscreen();
      }
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };
  render() {
    const { imageSrc, layoutDetails } = this.props;
    let self = this;
    function toggleFullScreen() {
      let element = document.querySelector(".thumbnail-preview-modal");
      var isInFullScreen =
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null);

      var docElm = element;
      if (!isInFullScreen) {
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
        } else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen();
        }
      } else {
        self.closeFullScreen();
      }
    }
    return (
      <div className="thumbnail-preview-modal">
        <div className="thumb-modal-header">
          {layoutDetails.desc && (
            <div className="float-left">
              <div className="layout-title">{layoutDetails.desc}</div>
            </div>
          )}

          <div className="float-right">
            <span style={{ cursor: "pointer" }} onClick={this.handleClose}>
              X
            </span>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="thumbnail-modal-body">
          <img
            src={imageSrc}
            alt="full-view"
            style={{ boxShadow: "0 0 20px" }}
          />
        </div>
        <div className="thumbnail-modal-footer">
          <div
            className="preview-full-screen"
            title="Full Screen Mode"
            onClick={toggleFullScreen}
          >
            <img src="/icons/expandIcon.png" style={{ width: "20px" }} />
          </div>
        </div>
      </div>
    );
  }
}
export default ThumbnailPreview;
