import React, { Component } from "react";
import CartContext from "../../context/cart/CartContext";
import { TOGGLE_PRODUCT_EXPAND } from "../../actions";
import OrderProductSetListContainer from "../../containers/orders/OrderProductSetListContainer";
import PaymentContainer from "../../containers/orders/PaymentContainer";
import ShareProductContainer from "../../containers/orders/ShareProductContainer";
import DownloadFilesContainer from "../../containers/orders/DownloadFilesContainer";
// "../containers/orders/DownloadFilesContainer";

export default class Order extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props, isAllAddedToCart: false };
  }
  onExpandToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ expand: !this.state.expand });
    // this.props.toggleView({
    //   reducerKey: TOGGLE_PRODUCT_EXPAND,
    //   id: this.props.product.id
    // });
    this.props.getSets(this.props.product.id);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.product !== this.state.product) {
      this.setState({ product: nextProps.product });
    }
  }

  componentDidMount() {
    this.props.getPrice(this.props.product.id);
    // this.props.getOrders({ status: "pending" });
  }

  setCartQty(){
    // Timeout is used as state updates are asyn! Important
    setTimeout(() => this.context.setqty(this.props.cart_product.num_files), 1000);
  }

  getPaymentDetails = (order, product) => {
    if (
      (order && order.status == "paid" && product.freeze_count == order.freeze_count) ||
      (product.pricing && product.pricing.price_for_files === 0)
    ) {
      return (
        <ul>
          <li>Download</li>
        </ul>
      );
    } else if (order && order.status == "pending") {
      return (
        <ul>
          <PaymentContainer product_id={product.id} pg_order_id={order.pg_order_id} />
        </ul>
      );
    } else {
      return (
        <ul>
          <PaymentContainer product_id={product.id} />
        </ul>
      );
    }
  };

  getCartDetails = (order, product) => {
   // if (product.product_role == "viewer") return null;
    if (
      order &&
      order.status == "paid" &&
      product.freeze_count == order.freeze_count &&
      this.isProductAddToCart(product.id, "order")
    ) {
      return (
        <ul>
          <li>Already Ordered</li>
        </ul>
      );
    } else {
      if (product.product_role == "viewer") return null;
      return (
        
        <ul>
          <li>
            {this.isProductAddToCart(product.id, "order") ? (
              <a
                className="no-textcolor"
                onClick={() => {
                  this.props.addToCart({ product_id: product.id, setno: "all", type: "remove", });
                  this.setState({
                    isAllAddedToCart: !this.state.isAllAddedToCart,
                  });
                  this.setCartQty();
                }}
              >
                Remove all Product Set Records from cart
              </a>
            ) : (
              <a
                className="no-textcolor"
                onClick={() => {
                  this.props.addToCart({ product_id: product.id, });
                  this.setState({
                    isAllAddedToCart: !this.state.isAllAddedToCart,
                  });
                  this.setCartQty();
                }}
              >
                Add all Product Set Records to Cart
              </a>
            )}
          </li>
        </ul>
        
      );
    }
  };

  
  isProductAddToCart = (product_id, type) => {
    const { cart_product: product_cart, product_set, product } = this.state;
    let cart_product = {};
    let not_ordered_product = {};
    if (type == "order")
    not_ordered_product = product.order.filter(notOrdered=>notOrdered.status != "paid")
    //cart_product = type == "cart" ? [product_cart] : (product && product.order) || []; Bala introduced this. We may not need this.
    cart_product = type == "cart" ? [product_cart] : (product && not_ordered_product) || []; 
    let isAddedToCart = this.state.isAllAddedToCart; 
    if (cart_product && cart_product[0]) {
      for (let index = 0; index < cart_product.length; index++) {
        const cp = cart_product[index];
        let sets = (cp && cp.order_details && cp.order_details.sets) || {};
        if (Object.keys(sets).includes("all")) {
          isAddedToCart = !this.state.isAllAddedToCart;
          break;
        }
        isAddedToCart = this.state.isAllAddedToCart;
        
      }
    }
    return isAddedToCart;
    
  };

  // Add to Cart is not flipping to Remove and vice versa!
  OriginalisProductAddToCart = (product_id, type) => {
    const { cart_product: product_cart, product_set, product } = this.state;
    let cart_product = {};
    cart_product = type == "cart" ? [product_cart] : (product && product.order) || [];
    let isAddedToCart = false; 
    if (cart_product && cart_product[0]) {
      for (let index = 0; index < cart_product.length; index++) {
        const cp = cart_product[index];
        let sets = (cp && cp.order_details && cp.order_details.sets) || {};
        if (Object.keys(sets).includes("all")) {
          isAddedToCart = true;
          break;
        }
        isAddedToCart = false;
        
      }
    }
    return isAddedToCart;
    
  };

  getOrderDetails1 = (product) => {
    const pricing = product.pricing;
    const order = product.order;
    if (order && order.status === "paid") {
      return (
        <ul>
          <li>No. of files: {order.num_files}</li>
          <li>Total Amount: {order.total_amount}</li>
        </ul>
      );
    } else if (pricing) {
      return (
        <ul>
          <li>{pricing && pricing.pages_count && pricing.pages_count.total} Files</li>
          <li>Total Amount: {pricing.price_total}</li>
        </ul>
      );
    }
  };

  getOrderDetails(product) {
    const pricing = product.pricing;
    const order = (product.order || []).find((o) => o.status == "pending");
    if (order && order.status == "pending") {
      return (
        <ul>
          <li>Total files: {order.num_files}</li>
          <li>Price per File: ${order.price_first_page / 100}</li>
          <li>Total files amount: ${order.total_files_amount / 100}</li>
          <li>Convenience Charges: ${(order.total_amount - order.total_files_amount) / 100}</li>
          <li>Grand Total Amount: ${order.total_amount / 100}</li>
        </ul>
      );
    }
    // if (order && order.status === "paid") {
    //   return (
    //     <ul>
    //       <li>No. of files: {order.num_files}</li>
    //       <li>Total Amount: {order.total_amount}</li>
    //     </ul>
    //   );
    // } else if (pricing) {
    //   return (
    //     <ul>
    //       <li>{pricing && pricing.pages_count && pricing.pages_count.total} Files</li>
    //       <li>Total Amount: {pricing.price_total}</li>
    //     </ul>
    //   );
    // }
  }

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  render() {
    const product = this.state.product;
    const expand = this.state.expand;
    const order = this.state.product.order;
    const { isShowDownloads } = this.state;

    const readOnly = (
      <a className="no-textcolor" onClick={this.onExpandToggle}>
        {this.getExpandJSX(expand)} {product.name}
      </a>
    );

    let productSets = null;
    if (expand == true) {
      productSets = (
        <React.Fragment>
          <li>
            <a className="no-textcolor" onClick={this.onProductSetsToggle}>
              {this.getExpandJSX(expand)} Product Sets
            </a>
            <OrderProductSetListContainer
              product_role={product.product_role}
              product_id={product.id}
              order_status={order.status}
            />
          </li>

          {product.product_role != "viewer" ? (
          <li>
            {this.getExpandJSX(expand)} All Product Sets

            {/* {this.getOrderDetails(product)} */}
            {/* {this.getPaymentDetails(order, product)} */}

            {this.getCartDetails(order, product)}
            </li>) : ("")}

            {/* <ShareProductContainer
            labelText="Share product"
            product_id={this.state.product.id}
            show={false}
            is_password_set={product.is_password_set}
          /> */}
        </React.Fragment>
      );
    }
    let orders = product.order || [];
    
    let isAnyPaidOrders = orders.some((o) => o.status == "paid");
    return (
      <React.Fragment>
        <li>
          <a className="no-textcolor" onClick={this.onExpandToggle}>
            {this.getExpandJSX(expand)} {this.state.treeName || "View Final Product Files"}
          </a>
        </li>
        {this.state.expand && <ul>{productSets}</ul>}
          {product.product_state == "completed" && isShowDownloads && isAnyPaidOrders && (
          <React.Fragment>
            <DownloadFilesContainer
              product_id={product.id}
              type="download"
              treeName="Download Product Files"
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
    // return (
    //   <li key={product.id}>
    //     {readOnly}
    //     {expand && (
    //       <React.Fragment>
    //         <li>{this.getExpandJSX(expand)} View Final Product Files</li>
    //         <ul>{productSets}</ul>
    //       </React.Fragment>
    //     )}
    //   </li>
    // );
  }
}

Order.contextType=CartContext
