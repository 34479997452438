import React from "react";
import { Form, Modal, Button } from "react-bootstrap";

import { FieldGroup } from "./bootstrap/Forms.FieldGroup";
import { ENDPOINTS } from "../constants/endpoints";
import { formDataToJSON } from "../adukku_utils/array";

export default class AddProductSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overlay: props.overlay || { visible: false },
      is_preset_display: false,
      categories: props.categories,
      sub_categories: props.sub_categories,
      voice: 'NO-VOICE',
      role: null,
      countryCode: this.getCookie("countryCode")
    };
    this.showAddSetOverlay = this.showAddSetOverlay.bind(this);
    this.hideAddSetOverlay = this.hideAddSetOverlay.bind(this);
    this._handleValidSubmit = this._handleValidSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.state) {
      this.setState(nextProps);
    }
    if (
      this.props.categories &&
      this.props.sub_categories &&
      (this.props.categories.length != nextProps.categories.length ||
        this.props.sub_categories.length != nextProps.sub_categories.length)
    ) {
      this.setState({
        categories: nextProps.categories,
        sub_categories: nextProps.sub_categories,
      });
    }
  }

  componentDidMount() {
    let user_role = document.getElementById("user_role");
    user_role = user_role.innerHTML;
    this.setState({ role: user_role });
    this.props.getCategories();
  }

  showAddSetOverlay(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.showOverlay("addProduct");
  }

  hideAddSetOverlay(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.hideOverlay("addProduct");
  }

  handlePresetToggle = (e) => {
    this.setState({ is_preset_display: !this.state.is_preset_display });
  };

  getProductCategories = () => {
    let categories = [
      "SCHOOL",
      "COLLEGE",
      "UNIVERSITY",
      "PRE_SCHOOL",
      "PLAY_SCHOOL",
      "INDIVIDUAL",
      "STUDENT",
      "ALUMNI",
      "TEACHER",
      "CORPORATE",
      "DOCTORS-CLUB",
      "SPORTS",
      "BAR-ASSOCIATION",
      "MILITARY",
      "NAVY",
      "NATURE-CLUB",
      "WALLSTREET",
      "MUSIC-CLUB",
      "AIRFORCE",
      "GOVERNMENT",
      "OTHERS",
    ];
    const { categories: myCategories } = this.state;
    myCategories.forEach((sc) => {
      if (!categories.includes(sc)) {
        categories.push(sc);
      }
    });
    return categories;
  };

  getProductSubCategories = () => {
    let subCategories = [
      "COMPOSITES",
      "PICTURE_BOOKLET",
      "FOLDER",
      "GRADUATION_FOLDER",
      "PORTRAIT",
      "PASSPORT",
      "ID_CARD",
      "REPORT_CARD",
      "CERTIFICATES",
      "YEAR_BOOK",
      "PAST_PRESENT",
      "BUNDLE",
      "ALL_IN_ONE",
      "OTHER",
    ];
    const { sub_categories } = this.state;
    sub_categories.forEach((sc) => {
      if (!subCategories.includes(sc)) {
        subCategories.push(sc);
      }
    });
    return subCategories;
  };

  getVoiceOptions = () => {
    if (this.state.countryCode == 'IN') {
      return [
        { label: "NO-VOICE", value: "NORMAL" },
        { label: "VOICE-30SEC - Additional ₹200 per Record", value: "VOICE-30SEC"},
        { label: "VOICE-15SEC - Additional ₹127 per Record", value: "VOICE-15SEC"},
      ];
      
    }
    else {
      return [
        { label: "NO-VOICE", value: "NORMAL" },
        { label: "VOICE-30SEC - Additional $4 per Record", value: "VOICE-30SEC"},
      ];
    }
  };

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  _handleValidSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    let formData = formDataToJSON(new FormData(e.target));
    formData.is_preset = formData.is_preset === "true" ? true : false;
    formData.name = formData.name.trim()    
    formData.category =
      this.state.category == "OTHERS" ? this.state.product_category : this.state.category;
    formData.sub_category =
      this.state.sub_category == "OTHER"
        ? this.state.product_sub_category
        : this.state.sub_category;
    formData.prtype = 
      this.state.voice == 'NO-VOICE' ? 'NORMAL' : this.state.voice;
    let endpoint = ENDPOINTS.products.create;
    let url = endpoint.uri;
    let method = endpoint.method;
    this.props.createProduct(url, method, formData);
    let is_preset_display = this.state.is_preset_display
      ? !this.state.is_preset_display
      : this.state.is_preset_display;
    this.setState({ is_preset_display: is_preset_display });
    this.props.hideOverlay("addProduct");
    this.props.getCategories();
  }

  render() {
    const is_preset_display = this.state.is_preset_display;
    const isVisible = this.state.overlay.visible;
    const product = this.state.product;
    const style = {
      display: is_preset_display ? "block" : "none",
    };

    const productCategories = this.getProductCategories();
    const productSubCategories = this.getProductSubCategories();
    const voiceOptions = this.getVoiceOptions();

    return (
      <li>
        <a onClick={this.showAddSetOverlay}>{this.props.labelText}</a>
        <Modal show={isVisible} onHide={this.hideAddSetOverlay}>
          <Form onSubmit={this._handleValidSubmit} className="override_form">
            <Modal.Header closeButton>
              <Modal.Title>Create new product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FieldGroup
                id="formControlName"
                type="text"
                pattern="[a-zA-Z0-9_\-' ]*"
                title="Only Alphabets,numbers,',-,_ and space allowed"                
                label="Name:"
                name="name"
                placeholder="Product Name"
                required="required"
              />
              <FieldGroup
                id="formControlPages"
                type="number"
                label="Number of pages:"
                name="pages"
                placeholder="Number of pages"
                required="required"
              />
              {this.state.role === "admin" && (
                <FieldGroup
                  id="formControlIsPreset"
                  type="checkbox"
                  label="Preset:"
                  value={this.state.is_preset_display}
                  name="is_preset"
                  placeholder="Preset"
                  onChange={this.handlePresetToggle}
                  className="override_checkbox"
                />
              )}
              <FieldGroup
                componentClass="select"
                id="formControlPreset"
                label="Select Product Category:"
                name="category"
                placeholder="Select Product Category"
                onChange={({ target: { name, value } }) => {
                  this.setState({ [name]: value });
                }}
                value={this.state.category}
              >
                <option key="None">{null}</option>
                {productCategories.map((category) => {
                  return (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  );
                })}
              </FieldGroup>
              {this.state.category == "OTHERS" && (
                <FieldGroup
                  id="formControlIsPresetSticker"
                  type="text"
                  label="Product Category:"
                  name="product_category"
                  placeholder="Eg: Background"
                  onChange={({ target: { name, value } }) => {
                    this.setState({ [name]: value });
                  }}
                  value={this.state.product_category}
                />
              )}
              <FieldGroup
                componentClass="select"
                id="formControlPreset"
                label="Select Product Sub Category:"
                name="sub_category"
                placeholder="Select Product Category"
                onChange={({ target: { name, value } }) => {
                  this.setState({ [name]: value });
                }}
                value={this.state.sub_category}
              >
                <option key="None">{null}</option>
                {productSubCategories.map((scategory) => {
                  return (
                    <option key={scategory} value={scategory}>
                      {scategory}
                    </option>
                  );
                })}
              </FieldGroup>
              {this.state.sub_category == "OTHER" && (
                <FieldGroup
                  id="formControlIsPresetSticker"
                  type="text"
                  label="Product Sub Category:"
                  name="product_sub_category"
                  placeholder="Eg: Background"
                  onChange={({ target: { name, value } }) => {
                    this.setState({ [name]: value });
                  }}
                  value={this.state.product_sub_category}
                />
              )}
              <FieldGroup
                componentClass="select"
                id="formControlVoice"
                label="Select Voice option(s):"
                name="voice"
                placeholder="Select Voice options"
                onChange={({ target: { name, value } }) => {
                 this.setState({ [name]: value }, ()=>{value=this.state.voice});
                }}
                value={this.state.voice}
              >
                {voiceOptions.map((vo) => {
                  return (
                    <option key={vo} value={vo.value}>
                      {vo.label}
                    </option>
                  );
                })}
              </FieldGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit">Submit</Button>
              {/* <Button onClick={this.hideAddSetOverlay}>Cancel</Button> */}
            </Modal.Footer>
          </Form>
        </Modal>
      </li>
    );
  }
}
